<template>
  <div>
    <b-row class="mb-3">
      <b-col>
        <div class="d-inline-block mr-3">
          <span class="page-header">Webhooks</span>
        </div>
        <webhook-recent-activity-log
          class="align-top pt-3"
          :bot-id="botId"
          :webhooks="webhooks"
          @selected="selectedWebhookFromActivityLog"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" xl="3" lg="4" class="overflow-auto panel">
        <b-row class="">
          <b-col>
            <bot-selector @botChanged="botChanged" />
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col>
            <webhook-apis v-model="webhookApiId" :bot-id="botId" />
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" xl="9" lg="8">
        <b-row>
          <b-col>
            <b-form-input
              type="search"
              v-model="searchKeywords"
              size="sm"
              placeholder="search ..."
              debounce="300"
            />
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col>
            <span class="ml-2">Webhooks ({{ webhooks.length }})</span>
            <b-button class="ml-3" variant="primary" size="sm" @click="addItem">
              Create
              <b-icon-plus />
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mt-3 overflow-auto list-panel">
          <b-col>
            <b-list-group>
              <b-list-group-item
                v-for="webhook in webhooksSorted"
                :key="webhook.webhookId"
              >
                <b-row>
                  <b-col>
                    <span
                      class="webhook-name d-inline-block text-truncate"
                      :title="webhook.webhookName"
                    >
                      {{ webhook.webhookName }}
                    </span>
                    <b-icon-trash
                      class="btn-inline-text mt-1 ml-1 float-right"
                      @click="Remove(webhook)"
                      title="Delete"
                    ></b-icon-trash>
                    <b-icon-pencil-square
                      class="btn-inline-text mt-1 ml-1 float-right"
                      @click="LoadWebhook(webhook)"
                      title="Edit"
                    ></b-icon-pencil-square>
                    <webhook-activity-log
                      class="btn-inline-text float-right"
                      :bot-id="botId"
                      :webhook-id="webhook.webhookId"
                    />
                  </b-col>
                </b-row>
                <div></div>
              </b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-modal
      v-model="formModal"
      size="xl"
      :title="formItem.webhookId ? 'Edit' : 'Add'"
      button-size="sm"
      scrollable
      @ok="submit"
      ok-title="Submit"
    >
      <b-form v-if="formItem.webhookApiId" ref="main-form">
        <b-form-group
          v-if="formItem.intentId"
          :label="'Intent ID: ' + formItem.intentId"
        ></b-form-group>
        <b-row class="form-group">
          <b-col cols="12" md="6" lg="4">
            <label class="control-label" for="form-webhookApiId"
              >Webhook API</label
            >
          </b-col>
          <b-col>
            <b-select
              id="form-webhookApiId"
              :options="webhookApis"
              v-model="formItem.webhookApiId"
              value-field="webhookApiId"
              text-field="apiKey"
              size="sm"
              disabled
            />
          </b-col>
        </b-row>
        <b-row class="form-group">
          <b-col cols="12" md="6" lg="4">
            <label class="control-label" for="form-webhookName"
              >Webhook Name</label
            >
          </b-col>
          <b-col>
            <b-form-input
              id="form-webhookName"
              ref="form-webhookName"
              v-model="formItem.webhookName"
              size="sm"
              minLength="6"
              maxLength="50"
              debounce="500"
              @change="formItem.webhookName = _sanitize($event)"
              required
            />
          </b-col>
        </b-row>

        <b-row class="form-group mt-3">
          <b-col>
            <hr />
            <h6>
              Process
              <span class="clickable text-primary ml-2" @click="AddInput">
                <b-icon-plus-square />
              </span>
            </h6>
          </b-col>
        </b-row>
        <b-card no-body v-if="formItem.webhookData.process.length">
          <b-card
            v-for="(input, index) in formItem.webhookData.process"
            :key="index"
            header-class="py-1 px-3"
            body-class="pt-2 pb-0 px-3"
          >
            <!--                      <template #header>-->
            <!--                        <h6 class="mb-0 d-inline-block align-top text-muted">{{ 'Process ' + (index + 1) }}</h6>-->
            <!--                        <b-icon-x class="clickable font-weight-bold float-right" @click="RemoveInput(input)"-->
            <!--                                  shift-v="-2"-->
            <!--                                  style="font-size: 1.2em;"/>-->
            <!--                      </template>-->
            <b-row>
              <b-col>
                <http-request-builder
                  class="form-group"
                  v-model="input.process"
                />
              </b-col>
              <b-col cols="1">
                <b-icon-x
                  class="clickable font-weight-bold float-right"
                  @click="RemoveInput(input)"
                  title="remove"
                  shift-v="-2"
                  style="font-size: 1.2em"
                />
              </b-col>
            </b-row>
          </b-card>
        </b-card>
        <small v-else class="text-muted"
          >Add url to be processed if it requires external data.</small
        >
        <hr />
        <output-builder
          :webhook-tags="webhookTags"
          :existing-outputs="formItem.webhookData.output"
          @outputsChanged="outputsChanged"
        ></output-builder>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import httpclient from '@/mixins/HttpHandler';
import HttpRequestBuilder from '@/components/HttpRequestBuilder';
import OutputBuilder from '@/components/Webhooks/OutputBuilder';
import BotSelector from '@/components/UI/BotSelector';
import WebhookApis from '@/components/Webhooks/WebhookApis';
import WebhookRecentActivityLog from '@/components/Bots/WebhookRecentActivityLog.vue';
import WebhookActivityLog from '@/components/Bots/WebhookActivityLog.vue';

export default {
  /*global _*/
  name: 'webhooks',
  components: {
    WebhookActivityLog,
    WebhookRecentActivityLog,
    HttpRequestBuilder,
    OutputBuilder,
    BotSelector,
    WebhookApis,
  },
  mixins: [httpclient],
  mounted() {
    const botId =
      Number(this.$route.params.id) || this.$store.state.selectedBotId;
    if (botId) this.botChanged(botId);
  },
  data() {
    return {
      path: 'webhooks',
      botId: 0,
      bots: [],
      webhookApiId: 0,
      webhookApis: [],
      webhooks: [],
      formItem: {},
      searchKeywords: '',
      formModal: false,
    };
  },
  computed: {
    webhooksSorted() {
      let list = this.webhooks;
      list = _.filter(list, (item) => {
        return item.webhookName.toLowerCase().includes(this.searchKeywords);
      });
      return _.orderBy(list, ['webhookName'], ['asc']);
    },
    webhookTags() {
      const vm = this;
      let tags = [];
      _.forEach(vm.formItem.webhookData.process, function (process) {
        if (process.process.result_handler.type === 'dropDownList') {
          tags.push(process.process.result_handler.tag_name);
        }
      });
      return tags;
    },
  },
  watch: {
    webhookApiId() {
      this.GetList();
    },
    'formItem.webhookName': function (newVal) {
      let input = this.$refs['form-webhookName'];
      if (input) {
        //if this is edit and name is similar to itself, then ignore
        if (this.formItem.webhookId) {
          let selectedWebhook = _.find(this.webhooks, {
            webhookId: this.formItem.webhookId,
          });
          if (newVal === selectedWebhook.webhookName) {
            input.setCustomValidity('');
            return;
          }
        }
        //check if it is duplicated
        if (_.some(this.webhooks, { webhookName: newVal })) {
          input.setCustomValidity('Webhook name already exists');
        } else {
          input.setCustomValidity('');
        }
      }
    },
  },
  methods: {
    botChanged(botId) {
      if (botId) this.botId = botId;
      this.httpclient
        .get('/api/webhookapis', { params: { botId: botId } })
        .then((resp) => {
          this.webhookApis = _.orderBy(resp.data, ['apiKey'], ['desc']);
          this.webhookApiId = this.webhookApis[0].webhookApiId;

          this.GetList();
        });
    },
    JsonNotValid() {
      this.jsonNotValid = true;
    },
    GetList() {
      const vm = this;
      vm.httpclient
        .get('/api/webhooks', { params: { webhookApiId: vm.webhookApiId } })
        .then(function (resp) {
          vm.webhooks = resp.data;
          vm.formItem = {};
        });
    },
    AddInput() {
      this.formItem.webhookData.process.push(_.cloneDeep(_defaultProcess));
    },
    RemoveInput(input) {
      this.formItem.webhookData.process.splice(
        this.formItem.webhookData.process.indexOf(input),
        1
      );
    },
    LoadWebhook(webhook) {
      this.formItem = {};
      let formItem = _.cloneDeep(webhook);
      formItem.webhookData = JSON.parse(formItem.webhookData);

      formItem.webhookData.process = _.map(
        formItem.webhookData.process,
        function (process) {
          return { process: process };
        }
      );

      let outputs = formItem.webhookData.output;
      if (!_.isArray(outputs)) {
        let output = _.pick(outputs, ['type', 'message', 'form', 'metadata']);
        outputs = [output];
      }
      _.forEach(outputs, (output) => {
        if (output.type === 'form') {
          _.forEach(output.form.content.field_list, function (field) {
            if (field.type === 'dropDownList') {
              if (Array.isArray(field.options) && field.options.length > 0) {
                field.optionsText = field.options.join('; ') + ';';
                field.optionsSource = 'Constants';
              } else {
                field.optionsSource = 'Webhook';
                field.optionsWebhook = {
                  tag_name: field.options.substring(
                    1,
                    field.options.length - 1
                  ),
                };
              }
            }
            if (!('masked' in field)) {
              field.masked = false;
            }
          });
        } else if (output.type === 'message') {
          output.form = null;
        }
      });
      formItem.webhookData.output = outputs;

      this.formItem = formItem;
      this.formModal = true;
    },
    addItem() {
      this.formItem = _defaultFormItem();
      this.formItem.webhookApiId = this.webhookApiId;
      this.formModal = true;
    },
    submit(modalEvent) {
      modalEvent.preventDefault();
      const vm = this;
      const mainForm = vm.$refs['main-form'];
      if (!mainForm.reportValidity()) {
        _.forEach(mainForm, (input) => {
          if (!input.validity.valid) {
            // eslint-disable-next-line no-console
            console.log(input);
          }
        });
        return;
      }
      let formItem = _.cloneDeep(vm.formItem);

      formItem.webhookData.process = _.map(
        formItem.webhookData.process,
        function (process) {
          return process.process;
        }
      );

      const outputs = formItem.webhookData.output;
      _.forEach(outputs, (output, index) => {
        if (output.type === 'form') {
          let formModel = [];
          output.form.content.field_list = _.map(
            output.form.content.field_list,
            function (field) {
              let mainField = _.pick(field, [
                'name',
                'title',
                'description',
                'type',
                'value',
                'ifRequired',
                'ifMasked',
                'dataType',
                'varExpressions',
                'lenRange',
                'prefDir',
                'masked', //for changing the field to asterisks
              ]);

              if (field.type === 'dropDownList') {
                if (field.optionsSource === 'Constants') {
                  mainField.options = field.options;
                } else if (field.optionsSource === 'Webhook') {
                  mainField.options = '[' + field.optionsWebhook.tag_name + ']';
                }
              }

              if (mainField.dataType) {
                formModel.push(
                  _.pick(mainField, [
                    'name',
                    'dataType',
                    'varExpressions',
                    'lenRange',
                    'prefDir',
                  ])
                );
              }

              return mainField;
            }
          );
          output = _.pick(output, ['type', 'message', 'form', 'metadata']);
          outputs[index] = {
            ...output,
            ...{ formModel: formModel },
          };
        } else {
          output.form = {};
          outputs[index] = _.pick(output, [
            'type',
            'message',
            'form',
            'metadata',
          ]);
        }
      });

      //change it to stringify format to store in DB
      formItem.webhookData = JSON.stringify(formItem.webhookData);

      if (formItem.webhookId) {
        vm.$bvModal
          .msgBoxConfirm('Proceed to edit the Webhook?', { centered: true })
          .then((value) => {
            if (value) {
              this.httpclient
                .put('api/webhooks/' + formItem.webhookId, formItem)
                .then(function () {
                  vm.GetList();
                  vm.$bvModal.msgBoxOk(
                    'The Webhook, [' +
                      formItem.webhookName +
                      '] had been edited successfully.',
                    {
                      okVariant: 'success',
                      centered: true,
                    }
                  );
                })
                .then(() => {
                  this.formModal = false;
                });
            }
          });
      } else {
        vm.$bvModal
          .msgBoxConfirm('Proceed to add the Webhook?', { centered: true })
          .then((value) => {
            if (value) {
              this.httpclient
                .post('api/webhooks', formItem)
                .then(function () {
                  vm.GetList();
                  vm.$bvModal.msgBoxOk(
                    'The Webhook, [' +
                      formItem.webhookName +
                      '] had been added successfully.',
                    {
                      okVariant: 'success',
                      centered: true,
                    }
                  );
                })
                .then(() => {
                  this.formModal = false;
                });
            }
          });
      }
    },
    Remove(item) {
      const vm = this;
      vm.$bvModal
        .msgBoxConfirm('Proceed to remove the Webhook?', {
          centered: true,
          bodyTextVariant: 'danger',
          okTitle: 'Remove',
          okVariant: 'danger',
        })
        .then((value) => {
          if (value) {
            this.httpclient
              .delete('api/webhooks/' + item.webhookId)
              .then(function () {
                vm.GetList();
                vm.$bvModal.msgBoxOk(
                  'The Webhook, [' +
                    item.webhookName +
                    '] had been removed successfully.',
                  {
                    okVariant: 'success',
                    centered: true,
                  }
                );
              });
          }
        });
    },
    outputsChanged(outputs) {
      this.formItem.webhookData.output = outputs;
    },
    selectedWebhookFromActivityLog(webhookId) {
      const webhook = _.find(this.webhooks, { webhookId: webhookId });
      this.LoadWebhook(webhook);
    },
  },
};

const _defaultFormItem = () => {
  return {
    webhookApiId: -1,
    webhookName: '',
    requireAuthentication: false,
    webhookData: {
      process: [],
      output: [],
    },
  };
};

const _defaultProcess = {
  process: {
    method: 'GET',
    url: 'https://',
    headers: [{ key: 'Accept', value: '*/*' }],
    json: {},
    result_handler: {
      type: 'system',
      tag_name: '',
    },
    success_message: '',
    fail_message: '',
    // params: [],
    // body: {
    //   dataType: 'text/plain',
    //   data: ''
    // },
  },
};
</script>

<style lang="scss" scoped>
.panel {
  height: calc(100vh - 180px);
}

.webhook-name {
  width: 80%;
  //cursor: pointer;
}

.list-panel {
  overflow: auto;
  white-space: nowrap;
  height: calc(100vh - 280px);
}
</style>
